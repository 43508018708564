<template>
  <ion-page>
    <Header :title="$t('cuisine_preference_optional')" @close-modal="handleLaterCuisine" />
    <ion-content v-if="isShow">
      <div class="px-2 py-2">
        <ion-text class="font-style-chip text-gray-900">
          {{ $t('please_select_cuisine') }}
        </ion-text>
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col
              v-for="(data, index) in cuisine"
              size="5.9"
              :class="['mt-2', cuisineIds.includes(data.id) ? 'active' : 'non-active']"
              :key="index"
              @click="handleSelectCuisine(index, data.id)"
            >
              <div class="d-flex form">
                <div class="country">
                  <div v-if="cuisineIds.includes(data.id)">
                    <ion-icon :icon="checkmarkOutline"></ion-icon>
                  </div>
                  <ion-text class="font-style-toolbar-segment text-gray-900">{{ data.name }}</ion-text>
                </div>
                <div class="image">
                  <td-image :image="data.image" :imagePath="IMAGE_PATH.CUISINES"></td-image>
                </div>
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="py-4" v-if="isOtherSelection">
            <ion-col>
              <ion-item class="ion-no-padding">
                <ion-label position="stacked" class="px-2 text-gray-900">{{
                  $t('what_type_cuisine_company_sell')
                }}</ion-label>
                <ion-input
                  :placeholder="$t('type_your_company_cuisine')"
                  v-model="typeCuisine"
                  maxlength="30"
                  class="px-2"
                ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row v-if="isOtherSelection">
            <ion-col class="d-flex justify-end">
              <ion-text class="text-gray-600 font-style-action-sheet-title">{{
                typeCuisine.length + '/30'
              }}</ion-text>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
    <ion-footer mode="ios" v-if="isShow">
      <div class="footer-selection">
        <ion-text class="font-style-toolbar-segment">
          {{ $t('selection') }}: {{ selection.length > 0 ? selection : '-' }}
        </ion-text>
      </div>
      <div class="footer-btn ion-padding">
        <ion-button shape="round" fill="outline" @click="handleLaterCuisine">{{ $t('later') }}</ion-button>
        <ion-button
          @click="handleSubmitCuisine"
          :disabled="isDisableBtn"
          :color="isDisableBtn ? 'grey' : 'primary'"
          >{{ $t('apply') }}</ion-button
        >
      </div>
    </ion-footer>
  </ion-page>
</template>

<script>
import { apolloClient } from '@/main';
import { AddCuisines, GetCuisines } from '@/modules/b2b/services/graphql';
import { IMAGE_PATH } from '@/modules/sale/constants/index';
import { useLoading } from '@/modules/shared/utils/';
import { errorAlert } from '@/utils/alert';
import { useBackButton } from '@ionic/vue';
import { checkmarkOutline } from 'ionicons/icons';
import { computed, defineAsyncComponent, onMounted, ref } from 'vue';
export default {
  components: {
    Header: defineAsyncComponent(() => import('@/components/header/index.vue'))
  },
  props: ['customerId', 'countryId', 'cuisinesCustomerDetails', 'isCheckCustomerDetailsPage', 'isNewCompany'],
  setup(props, ctx) {
    const cuisine = ref(null);
    const typeCuisine = ref('');
    const selection = ref([]);
    const cuisineIds = ref([]);
    const isShow = ref(false);
    const isGetIdActiveFirst = ref(true);
    const handleSelectCuisine = (index, id) => {
      if (cuisineIds.value.includes(id)) {
        const data = cuisineIds.value.filter((item) => item !== id);
        cuisineIds.value = data;
      } else {
        cuisineIds.value.push(id);
      }
      cuisine.value[index].active = !cuisine.value[index].active;
      handleGetCuisineActive();
    };
    const { createLoading, dismissLoading } = useLoading();
    const handleGetCuisineActive = () => {
      const cuisineActive = cuisine.value.filter((item) => item.active === true);
      selection.value = '';
      for (const value of cuisineActive) {
        isGetIdActiveFirst.value ? cuisineIds.value.push(value.id) : '';
        selection.value.length > 0
          ? (selection.value = selection.value + ' , ' + value.name)
          : (selection.value = value.name);
      }
    };
    const isOtherSelection = computed(() => {
      const item = cuisine.value.find((item) => item.name.toLowerCase() === 'others');
      return item.active;
    });
    const isDisableBtn = computed(() => {
      return selection.value.length === 0 || (isOtherSelection.value && typeCuisine.value.length === 0);
    });
    const handleGetCuisine = async () => {
      let cuisines;
      // check if customer details get props out get call api
      try {
        // const isCheckCustomerDetailsPage = route.currentRoute.value.params.id;
        if (!props.isCheckCustomerDetailsPage) {
          const { data } = await apolloClient.query({
            query: GetCuisines,
            variables: {
              customerId: props.customerId,
              countryId: props.countryId
            }
          });
          cuisines = data.getCuisines;
        } else {
          cuisines = JSON.parse(JSON.stringify(props.cuisinesCustomerDetails));
        }
        const item = cuisines.find((item) => item.name.toLowerCase() === 'others');
        if (item.customerCuisine.length > 0) {
          typeCuisine.value = item.customerCuisine[0].note;
        }
        cuisine.value = cuisines;
      } catch (e) {
        errorAlert(e.message);
      }
    };
    const handleAddCuisine = async () => {
      try {
        await apolloClient.mutate({
          mutation: AddCuisines,
          variables: {
            customerId: props.customerId,
            cuisineIds: cuisineIds.value,
            countryId: props.countryId,
            note: isOtherSelection.value ? typeCuisine.value : ''
          }
        });
        ctx.emit('apply');
      } catch (e) {
        errorAlert(e.message);
      } finally {
        await dismissLoading();
      }
    };
    const handleSubmitCuisine = async () => {
      await handleAddCuisine();
    };
    useBackButton(1, async () => {
      await handleLaterCuisine();
    });
    const handleLaterCuisine = async () => {
      ctx.emit('later');
    };
    onMounted(async () => {
      await createLoading();
      await handleGetCuisine();
      handleGetCuisineActive();
      await dismissLoading();
      isGetIdActiveFirst.value = false;
      isShow.value = true;
    });
    return {
      checkmarkOutline,
      cuisine,
      handleSelectCuisine,
      selection,
      isOtherSelection,
      typeCuisine,
      handleGetCuisine,
      isShow,
      handleAddCuisine,
      handleSubmitCuisine,
      handleLaterCuisine,
      isGetIdActiveFirst,
      cuisineIds,
      isDisableBtn,
      IMAGE_PATH,
      errorAlert
    };
  }
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
